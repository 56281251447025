//.login-form {
//  .ant-form-item-control-input-content {
//    > input, > .ant-input-password {
//      @apply min-h-12 rounded-lg text-base;
//    }
//  }
//}

.ant-collapse-content-box {
  padding: 0 !important;
}

.loading-transition-enter {
  opacity: 0;
  display: none;
}

.loading-transition-enter-active {
  display: flex;
  opacity: 1;
  transition: opacity 1000ms;

}

.loading-transition-enter-done {
  opacity: 1;
  display: flex;
}

.loading-transition-exit {
  opacity: 1;
}

.loading-transition-exit-active {
  opacity: 0;
  transition: opacity 1000ms;

}

.loading-transition-exit-done {
  display: none !important;
}


// form
.ant-form-item-label {
  label {
    @apply text-sm sm:text-base;
  }
}


.ant-form-item {
  @apply mb-3 sm:mb-4 ;
}


.ant-select {
  @apply text-sm sm:text-base ;
  .ant-select-selector {
    @apply h-10 rounded sm:h-12 sm:rounded-lg #{'!important'};
    input {
      @apply h-full #{'!important'};
    }

    .ant-select-selection-item {
      @apply text-sm sm:text-base flex items-center;
    }
  }
}

.ant-select-selection-placeholder {
  @apply text-sm sm:text-base flex items-center;
}


.ant-picker {
  @apply h-10 rounded sm:h-12 sm:rounded-lg #{'!important'};

  .ant-picker-input {
    @apply text-sm sm:text-base  #{'!important'};
    input {
      @apply text-sm sm:text-base  #{'!important'};
    }
  }
}


::placeholder {
  @apply text-sm sm:text-base;
}


// input
.ant-input {
  @apply text-sm sm:text-base h-10 sm:h-12 rounded sm:rounded-lg;
}

.ant-input-affix-wrapper {
  @apply h-10 sm:h-12 rounded sm:rounded-lg;
  .ant-input {
    @apply text-sm sm:text-base h-auto;
  }
}


// inputNumber
.ant-input-number-input {
  @apply text-center;
}

.ant-input-number-input-wrap {
  @apply h-full #{'!important'};
  input {
    @apply h-full #{'!important'};
  }
}

.ant-input-number-group-addon:first-child {
  @apply rounded-l sm:rounded-l-lg;
}

.ant-input-number-group-addon:last-child {
  @apply rounded-r sm:rounded-r-lg;
}


.ant-input-number-group-addon {
  background: #fff;
  color: #ccc;
  border-color: #ccc;
}

.swiper-wrapper {
  align-items: center;
}


.ant-checkbox-wrapper {
  .ant-checkbox-checked:after {
    @apply rounded;
  }

  .ant-checkbox {
    @apply top-1;
  }

  .ant-checkbox-inner {
    @apply rounded overflow-hidden sm:w-5 sm:h-5;
    &::after {
      @apply sm:left-1/4;
    }
  }

  span {
    @apply text-xs sm:text-base;
  }
}


// Promote Detail
// Tab 樣式
.ant-tabs {
  @apply text-base #{'!important'};
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  @apply py-2 text-lg #{'!important'};
  @media screen and (max-width: 475px) {
    @apply text-sm px-5 ml-0 #{'!important'};
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply text-black font-bold #{'!important'};
}

.ant-tabs-tab .ant-tabs-tab-btn {
  @apply text-gray1;
}

.ant-tabs-ink-bar {
  @apply h-0.5 #{'!important'};
  background-color: #DF062E !important;
}


// modal
.ant-modal-header {
  @apply border-none text-center pb-0 pt-14;
  .ant-modal-title {
    @apply font-bold text-base sm:text-xl;
  }
}

.ant-modal-content {
  @apply rounded-lg overflow-hidden #{'!important'};

  .ant-modal-footer {
    @apply px-4 sm:px-8 py-6;
  }
}

.ant-modal-close-x {
  @apply w-14 h-14 text-base flex justify-center items-center;
}


//collapse
.ant-collapse-header {
  @apply bg-gray5 font-bold text-xl px-6 py-4 #{'!important'};
}

.ant-collapse-content {
  @apply border-none;
}

.ant-collapse-item {
  @apply border-b-gray2
}

.ant-radio-wrapper, .ant-checkbox-wrapper {
  @apply text-base;
}

// Button
.ant-btn {
  @apply rounded sm:rounded-lg text-sm h-10 sm:text-base sm:h-12;
}

// Message
.ant-message-notice-content {
  .ant-message-custom-content {
    @apply flex items-center;
  }
}

.custom-ant-collapse-item {
  @apply border-solid border-1 sm:border-2 border-gray5 overflow-hidden #{"!important"};
  .ant-collapse-header {
    @apply bg-gray5 px-3 py-2 text-sm sm:text-xl sm:px-6 sm:py-4 #{"!important"};
  }
  .ant-collapse-content {
    @apply bg-white rounded-lg overflow-hidden #{"!important"};
  }
}

.cart-image-size {
  width: 100px;
  height: 100px;
  @media screen and (max-width: 475px) {
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 55px);
  }
}
