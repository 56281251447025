.swiper.feature-swiper
  width: 100%
  height: 850px
  margin-left: 0
  margin-right: 0
  .swiper-slide
    transform-origin: 50% 100%
    img
      width: 100%
  // .swiper-wrapper
  //   @for $i from 1 through 15
  //     .swiper-slide:nth-child(#{$i})
  //       // background-color: orange
  //       transform: translate3d(30px, 30px, 0px) rotate(180+$i*24deg) rotateX(0deg) rotateY(0deg) scale(1) !important
        // img
        //   transform: rotate(180+$i*24deg)
