$font_size: 6px

.App
  text-align: center

  h2
    font-size: $font_size*4
    margin-top: 20px
    margin-bottom: 20px
    font-weight: bold

  h3
    font-size: $font_size*3.5
    margin-top: 20px
    margin-bottom: 20px
    font-weight: bold

  h4
    font-size: $font_size*3
    margin-top: 20px
    margin-bottom: 20px

  p
    font-size: 16px
    margin-top: 20px
    margin-bottom: 20px
    z-index: 2

.header
  .App-logo
    margin: 20px

.container-wrap
  position: relative
  background: center / cover no-repeat url('../../images/bg_dot.svg')
  overflow: hidden

  .tittle-group
    display: flex
    justify-content: center
    align-items: center
    padding-top: 50px
    .tittle-EN
      font-family: Brigest Script
      font-size: $font_size*15
      margin-right: 20px
    .tittle-TW
      color: #fff
      font-size: $font_size*4
      border-radius: 30px
      background-color: #111
      padding: 5px 15px
  .text
    padding: 20px
    line-height: 30px
    max-width: 1200px

  .experience
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: 5% 70px / 70px no-repeat url('../../images/decoration_4.svg')

    .button-group
      position: relative
      display: flex
      justify-content: center
      align-items: center
      *
        cursor: pointer
      .apple-store
        margin-right: 20px

    #decoration_5
      position: absolute
      width: 25vw
      top: 550px
      right: -10%
      z-index: 1


    .box-list
      display: flex
      justify-content: center
      align-items: center
      flex-wrap: wrap
      max-width: 1200px
      margin: 50px 0 0
      z-index: 1
      .box
        flex: 1
        max-width: 270px
        min-height: 332px
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        background-color: #fff
        border: 3px solid #000
        border-radius: 30px
        box-sizing: border-box
        margin: 10px
        padding: 10px 5px
        .box-title-wrap
          width: 80%
          border-radius: 30px
          background-color: #333
          margin: 1vw 3vw
          h3
            margin: 5px
            color: #fff
        p
          margin-top: 10px
          margin-left: 20px
          margin-right: 20px
          flex: 1
        .svga
          width: 100%
          height: 150px
        #box-title-1
          background-color: #FFCB00
        #box-title-2
          background-color: #00A1E8
        #box-title-3
          background-color: #98CB33
        #box-title-4
          background-color: #CB3366

  .features
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: -3% 105% / 18% no-repeat url('../../images/decoration_2.svg')

    #decoration_6
      position: absolute
      width: 15vw
      top: 20px
      left: -3%
      z-index: 1
    .bg-wrap
      position: relative
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
      height: auto
      padding: 100px 0
      background: center / cover no-repeat url('../../images/bg-gray.svg')
      .swiper-descript
        position: absolute
        bottom: 100px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin-bottom: 50px
        img
          margin: 25px
        .descript
          z-index: 2
          cursor: pointer
    .menu-group
      display: flex
      justify-content: center
      align-items: center
      margin: 25px 50px 100px
      .menu
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        width: 30vw
        height: 42vw
        min-width: 400px
        min-height: 540px
        margin: 0 15px 0
        padding: 30px
        background-color: #fff
        border: 5px solid #000
        border-radius: 15px
        .menu-tittle
          width: 100%
          display: flex
          justify-content: flex-start
          align-items: center
          padding: 10px 0
          border-bottom: 5px solid #000
          .tittle-EN
            font-size: $font_size*10
            font-family: Brigest Script
            margin-left: 20px
            margin-right: 10px
            z-index: 1
          .tittle-TW
            font-size: $font_size*4
            font-weight: bold
          &::before
            content: ""
            position: absolute
            border-style: solid
            border-width: 90px 90px 0 0
            border-color: #FCEE21 transparent transparent transparent
            z-index: 0
        .menu-list-group
          width: 102%
          margin-right: -20px
          overflow-y: auto
          .menu-list
            position: relative
            width: 100%
            text-align: left
            .time
              width: 80px
              height: 20px
              font-size: $font_size*2
              line-height: 20px
              color: #fff
              background-color: #000
              border-radius: 3px
              margin-top: 15px
              text-align: center
            .name
              font-size: $font_size*3.5
              line-height: 70px
              color: #fff
              background-color: #000
              border-radius: 3px
              padding: 5px
            .text-wrap
              width: 98%
              border-bottom: 3px solid #000
              h3, .menu-text
                max-height: 30px
                width: 90%
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 1
                overflow: hidden
                text-align: start
                margin: 5px 0
              p
                margin: 5px 0
              .more
                position: absolute
                top: calc( 50% - 10px )
                transform: translateY(-50%)
                right: 0
                margin-right: 10px
          .news-list
            cursor: pointer
          .QA-text-wrap
            position: relative
            display: flex
            justify-content: space-between
            align-items: center
            border-top: 3px dashed #707070
            cursor: pointer
            img
              padding: 5px
          .question
            width: 32px
            height: 32px
            font-family: impact
            font-weight: normal
            line-height: 24px
            color: #fff
            background-color: #C21F3A
            box-sizing: border-box
            border-radius: 100%
            margin-right: 5px
            padding: 4px
            text-align: center
          .QA
            height: auto
        ::-webkit-scrollbar
          width: 4px
        ::-webkit-scrollbar-thumb
          background-color: #000
          border-radius: 30px
    .gotop
      position: absolute
      right: 20px
      bottom: 20px
      cursor: pointer
      z-index: 10

.footer
  background-color: #000
  color: #fff
  padding: 30px
  .icon-group
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    margin: 10px
    img
      width: 50px
      margin: 10px
  .contact
    display: flex
    justify-content: center
    align-items: center
    *
      margin: 10px
      font-weight: bold
  .copyright
    font-size: $font_size*2
    margin: 10px
    opacity: .5


@media (max-width: 1600px)
  .container-wrap
    .features
      .swiper
        width: 130%
      .bg-wrap
        .swiper-descript
          bottom: 75px


@media (max-width: 1200px)
  .container-wrap
    .experience
      .box-list
        margin: 50px 50px 0
        .box
          width: 350px
          height: 350px
          margin: 2vw
          max-width: 350px
          max-height: 350px
          .svga
            height: 200px
    .features
      .swiper
        width: 170%

@media (max-width: 1024px)
  .container-wrap
    .experience
      .box-list
        margin: 50px 0 0
        .box
          width: 320px
          height: 320px
          max-width: 320px
          max-height: 320px
          .svga
            height: 150px
    .features
      background: -10% 105% / 40% no-repeat url('../../images/decoration_2.svg')
      .swiper
        width: 200%
      .menu-group
        flex-direction: column
        .menu
          width: 70vw
          min-width: unset
          margin: 15px
          .menu-list-group
            .menu-list
              .text-wrap
                h3
                  -webkit-line-clamp: 2


@media (max-width: 768px)
  .container-wrap
    .experience
      #decoration_5
        top: 10%
      .button-group
        .google-play, .apple-store
          width: 35vw
      .box-list
        .box
          min-width: 40vw
          min-height: 330px
          .box-title-wrap
            h3
              font-size: $font_size*5
          p
            flex: 0
            font-size: $font_size*3.5
            line-height: 32px
          .svga
            height: 120px
    .tittle-group
      flex-direction: column
      .tittle-EN
        font-size: $font_size*10
        margin-right: 0
    .text
      font-family: Microsoft JhengHei
      font-size: $font_size*4.5
      line-height: 37px

    .features
      #decoration_6
        top: 3%
      background: -30% 102% / 50% no-repeat url('../../images/decoration_2.svg')
      .swiper
        width: 250%


@media (max-width: 640px)
  .container-wrap
    .experience
      .box-list
        .box
          .box-title-wrap
            h3
              font-size: $font_size*4
          p
            font-size: $font_size*3.5

    .features
      background: -30% 102% / 50% no-repeat url('../../images/decoration_2.svg')
      .swiper
        width: 320%
        height: 150vw

  // .footer
  //   .icon-group
  //     margin: 10px 0
  //     img
  //       width: 75px
  //   .contact
  //     flex-direction: column
  //     font-size: $font_size*4
  //     *
  //       font-weight: normal
  //   .copyright
  //     font-size: $font_size*4
  //     margin: 50px 10px


@media (max-width: 576px)
  .header
    .App-logo
      width: 35vw
  .container-wrap
    .experience
      .box-list
        margin: 50px 1vw 0
        .box
          min-width: unset
          max-width: 45vw
          min-height: unset
          max-height: 270px
          .svga
            height: 100px
          .box-title-wrap
            h3
              font-size: $font_size*3.5
          p
            margin: 0
            line-height: 24px
            font-size: $font_size*3
    .tittle-group
      flex-direction: column
      .tittle-EN
        font-size: $font_size*8
        margin-right: 0
      .tittle-TW
        font-size: $font_size*3
    .text
      font-family: Microsoft JhengHei
      font-size: $font_size*3
      line-height: 28px

    .features
      .bg-wrap
        .swiper-descript
          bottom: 110px
          img
            margin: 10px
      .menu-group
        .menu
          width: 80vw
          padding: 15px 20px 15px 10px
          .menu-tittle
            .tittle-EN
              font-size: $font_size*9
            .tittle-TW
              font-size: $font_size*3.5
      .gotop
        width: 50px

  .footer
    .contact
      flex-direction: column


@media (max-width: 440px)
  .container-wrap
    .features
      .bg-wrap
        padding: 75px 0
        .swiper-descript
          top: 200vw
          img
            width: 60vw
          .descript
            width: 65vw
      .swiper
        height: 640px
  .footer
    padding: 10px 0
    // .icon-group
    //   img
    //     width: 65px
    //     margin: 5px


@media (max-width: 370px)
  .container-wrap
    .experience
      .box-list
        margin: 50px 1vw 0
        .box
          min-width: 50vw
          max-width: 75vw
          min-height: unset
          max-height: 270px
          .svga
            height: 120px
          .box-title-wrap
            h3
              font-size: $font_size*3.5
          p
            margin: 0
            line-height: 24px
            font-size: $font_size*3

    .features
      .bg-wrap
        padding: 50px 0
        .swiper-descript
          top: 220vw
      .swiper
        height: 170vw
