.promote{
  font-family: 微軟正黑體;
}
/*.header{*/
/*  width: 100%;*/
/*  height: 64px;*/
/*  background: #4F4D52;*/
/*  padding: 0 40px;*/
/*}*/
.headerwhite{
  width: 100%;
  height: 64px;
  background: #fff;
  padding: 0 40px;
}

.footer{
  width: 100%;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer span {
  display: block;
  width: 100%;
  text-align: center;
}

.copyright{
  font-size: 12px;
  margin-top: 16px;
  opacity: 0.5;
}

@media screen and (min-width: 640px) {
  .footer {
    height: 180px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
  }

  .footer span {
    margin: 0 24px;
  }

  .copyright{
    margin-top: 30px;
  }
}

.body{
  min-height: calc( 100vh - 162px );
}

.link{
  cursor: pointer;
  color: #00A1E8;
  font-size: 12px;
}
.link:hover{
  color: #40a9ff;
}
.link:active{
  color: #096dd9;
}
