.body {
  position: relative;

  select {
    -webkit-appearance: none;
  }

  &:after {
    content: '';
    position: absolute;
    right: 14px;
    width: 0;
    height: 0;
    top: 50%;
    transform: translateY(calc(-50% - 4px));
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid rgb(119, 119, 119);
    clear: both;
    @apply sm:transform sm:-translate-y-1/2;
  }
}
