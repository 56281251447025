.facebook{
  color: #fff;
  background: #3B5998;
  &:hover,&:focus{
    background: #3B5998;
    border: #3B5998 solid 1px;
    color: #fff;
  }
}
.apple{
  color: #fff;
  background: #000;
  width: 100%;
  border-radius: 0.25rem;
  height: 40px;
  svg{
    height: 40px;
  }
  &:hover,&:focus{
    background: #0a0a0a;
    //border: #3B5998 solid 1px;
    color: #fff;
  }
}
.google{
  opacity: 1!important;
  box-shadow: none!important;
  border: 1px solid #CCC !important;
  height: 40px;
  border-radius: 0.25rem !important;
  >div{
    margin-right: 0 !important;
    padding-right: 4px !important;


  }
}
@media (min-width: 640px){
  .google,.apple{
    height: 48px;

  }
  .apple{
    svg{
      height: 48px
    }
  }
}
.gray{
  color: #fff;
  background: #4F4D52;
  &:hover,&:focus{
    background: #3b3a3d;
    border: #646168 solid 1px;
    color: #fff;
  }
}
