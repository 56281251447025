@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: '微軟正黑體';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #F8F7F7;
}

body::-webkit-scrollbar-thumb {
  background-color: #222;
  border-radius: 30px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #3c3c3c;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-button {
  background: transparent;
  height: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Brigest Script';
  src: local('Brigest Script'), url(./font/BRIGESTSCRIPT.TTF) format('opentype');
}

/*@import '~antd/dist/antd.css';*/

.scroll-style-modal::-webkit-scrollbar-track
{
  background-color: #fff;
}

.scroll-style-modal::-webkit-scrollbar
{
  width: 4px;
}

.scroll-style-modal::-webkit-scrollbar-thumb
{
  background: #CCCCCC;
  border-radius: 50px;
}

.over2line{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
