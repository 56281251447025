.lightBox-wrap
  h4
    margin-top: auto
    margin-bottom: auto

  position: fixed
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(#333, .7)
  box-sizing: border-box
  top: 0
  z-index: 1000
  padding: 20px
  .lightBox
    width: 640px
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 30px
    border: 5px solid #000
    background-color: #fff
    box-sizing: border-box
    margin: 10px
    .close
      width: 50px
      height: 50px
      position: absolute
      top: -25px
      right: -25px
      background-color: #fff
      border: 5px solid #000
      border-radius: 50px
      cursor: pointer
      .line1, .line2
        width: 30px
        height: 5px
        position: absolute
        top: 50%
        left: 50%
        background-color: #000
        border-radius: 30px
      .line1
        transform: translate(-50%, -50%) rotate(45deg)
      .line2
        transform: translate(-50%, -50%) rotate(-45deg)
      &:hover
        background-color: #222
        .line1, .line2
          background-color: #eee

    .question-wrap
      width: 90%
      max-height: 80vh
      overflow: hidden
      overflow-y: auto
      margin: 25px 50px
      .Q-text-wrap
        width: 100%
        position: relative
        display: flex
        justify-content: flex-start
        align-items: center
        border-bottom: 3px solid #000
        .question
          width: 32px
          height: 32px
          font-family: impact
          font-weight: normal
          line-height: 24px
          color: #fff
          background-color: #C21F3A
          box-sizing: border-box
          border-radius: 100%
          margin-right: 15px
          padding: 4px
          text-align: center
      .A-text-wrap
        width: 100%
        position: relative
        display: flex
        justify-content: flex-start
        align-items: flex-start
        .answer
          width: 32px
          height: 32px
          font-family: impact
          font-weight: normal
          line-height: 24px
          color: #000
          background-color: #FCEE21
          box-sizing: border-box
          border-radius: 100%
          margin-right: 15px
          padding: 4px
          text-align: center
      h2, h3
        text-align: left
        width: 95%


    .news-wrap
      width: 90%
      height: 70vh
      display: flex
      flex-direction: column
      justify-content: flex-start
      overflow-y: auto
      margin: 45px 10px 45px 45px
      .pop-up-img
        width: 95%
        left: 0
        border-radius: 30px
      .news-list
        width: 95%
        position: relative
        margin-top: 25px
        text-align: left
        .company-logo
          width: 63px
          position: absolute
          top: -60px
          right: 30px
        .time
          width: 80px
          height: 20px
          font-size: 12px
          line-height: 20px
          color: #fff
          background-color: #000
          border-radius: 3px
          text-align: center
        .text-wrap
          h2
            width: 100%
            padding-bottom: 10px
            border-bottom: 4px solid #000
    ::-webkit-scrollbar
      width: 4px
    ::-webkit-scrollbar-thumb
      background-color: #000
      border-radius: 30px


@media (max-width: 640px)
  .lightBox-wrap
    .lightBox
      .news-wrap
        margin: 45px 30px 30px 45px
