.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 100000;
  justify-content: center;
  align-items: center;
  display: flex;
}