.page {
  padding-top: 3rem;
}

.card{
  &__head {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 765px) {
  .page {
    padding-top: 1rem;
  }
  .card {
    &__head {
      align-items: flex-start;
      flex-direction: column;
    }
    &__title{
      margin-bottom: 0.75rem;
    }
  }
}

@media (max-width: 640px) {
  .creditcard{
    &__input{
      margin-bottom: 0.5rem;
    }
  }
}
