//5
.product {
  min-width: 19%;

  //// 如果最后一行是4个元素
  &:last-child:nth-child(5n - 0) {
    margin-right: 0 !important;
  }

  //// 如果最后一行是3个元素
  &:last-child:nth-child(5n - 1) {
    margin-right: calc(19% + 5% / 4) !important;
  }

  //// 如果最后一行是2个元素
  &:last-child:nth-child(5n - 2) {
    margin-right: calc(38% + 10% / 4) !important;
  }

  /* 如果最后一行是1个元素 */
  &:last-child:nth-child(5n - 3) {
    margin-right: calc(57% + 15% / 4) !important;
  }
}


//4
@media screen and (max-width: 1470px) {
  .product {
    width: 24%;

    //// 如果最后一行是3个元素
    &:last-child:nth-child(4n - 0) {
      margin-right: 0 !important;
    }

    //// 如果最后一行是2个元素
    &:last-child:nth-child(4n - 1) {
      margin-right: calc(24% + 4% / 3) !important;
    }

    //// 如果最后一行是1个元素
    &:last-child:nth-child(4n - 2) {
      margin-right: calc(48% + 8% / 3) !important;
    }
  }
}


//3
@media screen and (max-width: 1160px) {
  .product {
    width: 32%;

    //// 如果最后一行是2个元素
    &:last-child:nth-child(3n - 0) {
      margin-right: 0 !important;
    }
    //// 如果最后一行是1个元素
    &:last-child:nth-child(3n - 1) {
      margin-right: calc(32% + 4% / 2) !important;
    }
  }
}

//2
@media screen and (max-width: 768px) {
  .product {
    width: 48%;
  }
}

