.banner
  position: relative
  height: 31.25vw
  .swiper-slide
    img
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      width: 100%
      min-height: 100%
  .swiper-button-prev, .swiper-button-next
    width: 50px
    height: 50px
    border-radius: 50px
  .swiper-button-prev
    left: 5vw
    background: center / cover no-repeat url('../../images/prev.svg')
    &::after
      content: ""
  .swiper-button-next
    right: 5vw
    background: center / cover no-repeat url('../../images/next.svg')
    &::after
      content: ""


@media (max-width: 768px)
  .banner
    height: 53.33vw
    .swiper-button-prev, .swiper-button-next
      background: none